










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
    },
})
export default class StyleSelect extends Vue {
    /** S data **/
    @Prop() value!: number
    @Prop() data!: any[]
    /** E data **/
    select = 0
    /** S watch **/
    @Watch('value', { immediate: true })
    valueChange(val: number) {
        this.select = val
    }
    /** E watch **/

    /** S methods **/
    onConfirm() {
        this.$emit('input', this.select)
    }
    /** E methods **/
}
