import { render, staticRenderFns } from "./contents.vue?vue&type=template&id=2def3b82&scoped=true&"
import script from "./contents.vue?vue&type=script&lang=ts&"
export * from "./contents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2def3b82",
  null
  
)

export default component.exports