





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import LinkSelect from '@/components/link-select/index.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        MaterialSelect,
        LinkSelect,
        Draggable,
    },
})
export default class StyleChose extends Vue {
    /** S props **/

    @Prop() value!: any[]

    /** E props **/

    /** S data **/

    /** E data **/
    get nav() {
        return this.value
    }

    set nav(val) {
        this.$emit('input', val)
    }

    /** S methods **/
    onAdd() {
        this.nav.push({
            url: '',
            name: '导航',
            link: {},
        })
    }
    onDelete(index: number) {
        this.nav.splice(index, 1)
    }
    /** E methods **/
}
