var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"title",style:({
            'background-color': _vm.styles.bg_color,
            'border-radius': ((_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_top) + "px " + (_vm.styles.border_radius_bottom) + "px " + (_vm.styles.border_radius_bottom) + "px")
        })},[(_vm.content.style == 1)?_c('div',{staticClass:"title-style1 flex-col col-center"},[_c('div',{staticClass:"title-content",style:({
                'color': _vm.styles.title_color,
                'font-size': ((_vm.styles.title_font_size) + "px")
            })},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(!_vm.content.hidden_subtitle)?_c('div',{staticClass:"title-subtitle",style:({
                'color': _vm.styles.subtitle_color,
                'font-size': ((_vm.styles.subtitle_font_size) + "px")
            })},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e()]):_vm._e(),(_vm.content.style == 2)?_c('div',{staticClass:"title-style2 flex"},[_c('div',{staticClass:"title-content m-r-10",style:({
                'color': _vm.styles.title_color,
                'font-size': ((_vm.styles.title_font_size) + "px")
            })},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{staticClass:"flex-1"},[(!_vm.content.hidden_subtitle)?_c('div',{staticClass:"title-subtitleline-1",style:({
                'color': _vm.styles.subtitle_color,
                'font-size': ((_vm.styles.subtitle_font_size) + "px")
            })},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e()]),(_vm.content.show_more)?_c('div',{staticClass:"title-more",style:({
                'color': _vm.styles.more_color,
            })},[_vm._v(" "+_vm._s(_vm.content.more_title)),_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }