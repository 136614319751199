





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import widgets from '@/components/decorate/widgets'
import { Getter } from 'vuex-class'
import PageInfo from '@/components/decorate/page-info.vue'
@Component({
    components: {
        PageInfo,
    },
})
export default class DecoratePhone extends Vue {

    @Prop() config!: any
    @Getter('widgetName') widgetName!: string
    /** S data **/
    widgets = widgets
    /** E data **/

    /** S computed **/

    get selectIndex() {
        return this.$store.state.decorate.selectIndex
    }
    set selectIndex(val) {
        this.$store.commit('setSelectIndex', val)
    }

    /** E computed **/
}
