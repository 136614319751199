






import { Component, Prop, Vue } from 'vue-property-decorator'
import StyleList from './style-list.vue'
@Component({
    components: {
        StyleList
    },
})
export default class Contents extends Vue {
    @Prop() content!: object | any[]
    @Prop() styles!: object | any[]
}
