







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CDialog from './dialog.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        CDialog,
        Draggable,
    },
})
export default class GoodsSelect extends Vue {
    @Prop({ default: () => [] }) value!: any
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: 10 }) limit!: number
    get selectData() {
        return this.value
    }
    set selectData(val) {
        this.$emit('input', val)
        this.$emit('change', val)
    }

    handleDelete(index: number) {
        this.selectData.splice(index, 1)
    }
}
