
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import Detail from './detail.vue'
@Component({
    components: {
        LsDialog,
        Detail,
    },
})
export default class Dialog extends Vue {
    @Prop({ default: () => ({}) }) value!: any[] | object
    @Prop({ default: false }) disabled!: boolean
    @Prop() level!: number
    category = {}
    @Watch('value', { immediate: true, deep: true})
    valueChange(val: any) {
        this.category = JSON.parse(JSON.stringify(val))
    }
    handleConfirm() {
        this.$emit('input', this.category)
    }
}
