






















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import GoodsSelect from '@/components/goods-select/index.vue'
import CategorySelect from '@/components/category-select/index.vue'
import GSelect from '../goodsgroup/select.vue'
import GAttr from '../goodsgroup/common-attr.vue'
import Draggable from 'vuedraggable'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        GoodsSelect,
        CategorySelect,
        GSelect,
        GAttr,
        Draggable,
    },
})
export default class Attribute extends Vue {
    /** S data **/

    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        let data = {
            key: 'content',
            value: val,
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    /** S methods **/
    draggableEnd($event: any) {
        const { newIndex, oldIndex } = $event

        if (oldIndex == this.content.active) {
            this.content.active = newIndex
            return
        }
        if (oldIndex > this.content.active && this.content.active >= newIndex) {
            this.content.active ++
            return
        }
        if (oldIndex < this.content.active && this.content.active <= newIndex) {
            this.content.active --
            return
        }
    }
    onAdd() {
        this.content.data.push({
            name: '选项卡',
            style: 1,
            goods_type: 1,
            show_title: 1,
            show_price: 1,
            show_scribing_price: 1,
            show_btn: 1,
            btn_text: '购买',
            btn_bg_type: 1,
            category: {
                id: '',
                name: '',
                number: 1,
            },
            data: [],
            title_color: '#101010',
            scribing_price_color: '#999999',
            price_color: '#FF2C3C',
            btn_bg_color: '#FF2C3C',
            btn_color: '#FFFFFF',
            btn_border_radius: 30,
            btn_border_color: '',
            root_bg_color: '',
            bg_color: '#FFFFFF',
            margin: 10,
            padding_top: 10,
            padding_horizontal: 10,
            padding_bottom: 10,
            border_radius_top: 4,
            border_radius_bottom: 4,
        })
    }
    onDelete(index: number) {
        if (this.content.data.length <= 1) {
            return this.$message.warning('最少保留一个')
        }
        this.content.data.splice(index, 1)
        this.content.active = 0
    }
    /** E methods **/
}
