





































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class StyleList extends Vue {
    @Prop() content!: any
}
