













































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import AttributeTabs from '@/components/decorate/attribute-tabs.vue'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import LinkSelect from '@/components/link-select/index.vue'
import NavList from '@/components/decorate/nav-list.vue'
@Component({
    components: {
        AttributeTabs,
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
        MaterialSelect,
        NavList,
        LinkSelect,
    },
})
export default class Attribute extends Vue {
    /** S data **/
    $refs!: { materialSelect: any }
    index = -1
    styleData = [
        {
            name: '图文',
            value: 1,
        },
        {
            name: '文字',
            value: 2,
        },
    ]
    indicatorData = [
        {
            name: '圆角',
            value: 1,
        },
        {
            name: '圆形',
            value: 2,
        },
    ]
    resetColor = ''
    /** E data **/

    /** S computed **/

    get content() {
        return this.$store.getters.content
    }

    set content(val) {
        let data = {
            key: 'content',
            value: val,
        }
        this.$store.commit('setAttribute', data)
    }
    get styles() {
        return this.$store.getters.styles
    }

    /** E computed **/

    /** S methods **/
 

    /** E methods **/
}
