
































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ColorSelect from '@/components/decorate/color-select.vue'
import StyleChose from '@/components/decorate/style-chose.vue'
import Slider from '@/components/decorate/slider.vue'
import AttributeItem from '@/components/decorate/attribute-item.vue'
@Component({
    components: {
        ColorSelect,
        StyleChose,
        Slider,
        AttributeItem,
    },
})
export default class Select extends Vue {
    @Prop() content!: any
    @Prop() styles!: any


    styleData = [
        {
            name: '大图模式',
            value: 1,
        },
        {
            name: '一行2个',
            value: 2,
        },
        {
            name: '横向滑动',
            value: 3,
        },
        {
            name: '列表模式',
            value: 4,
        },
    ]
}
