






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot,
    },
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: object | any
    get navList() {
        const {
            content: { data },
            styles: { nav_line, nav_line_num },
        } = this
        if (!Array.isArray(data)) return []
        return data.slice(0, nav_line * nav_line_num)
    }
}
