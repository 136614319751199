




































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class StyleChose extends Vue {
    /** S props **/

    @Prop({ default: 1 }) type!: number
    @Prop({ default: 'left' }) align!: string
    @Prop({ default: '#FF2C3C' }) color!: string
    @Prop({ default: 10 }) bottom!: number
    /** E props **/

    /** S computed **/

    /** E computed **/
}
