

import attribute from './attribute.vue'
import contents from './contents.vue'
import widget from './widget.vue'

export default {
    attribute,
    contents,
    widget
}