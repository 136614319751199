
































import { Component, Prop, Vue } from 'vue-property-decorator'
import GoodsSelect from '@/components/goods-select/index.vue'
import CategorySelect from '@/components/category-select/index.vue'
import Slider from '@/components/decorate/slider.vue'
@Component({
    components: {
        GoodsSelect,
        CategorySelect,
        Slider,
    },
})
export default class Select extends Vue {
    @Prop() content!: any
}
