var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"indicator",style:({bottom: (_vm.bottom + "px")})},[(_vm.type==1||_vm.type==2)?_c('div',{staticClass:"indicator-content",class:{
            fillet:_vm.type==1,
            circle:_vm.type==2,
        },style:({
            'text-align':_vm.align
        })},[_c('span',{staticClass:"indicator-item active",style:({
                'background-color':_vm.color
            })}),_c('span',{staticClass:"indicator-item"}),_c('span',{staticClass:"indicator-item"})]):_vm._e(),(_vm.type==3)?_c('div',{style:({
            'text-align':_vm.align,
        })},[_c('span',{staticClass:"indicator-number"},[_vm._v("1/5")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }