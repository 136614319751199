


































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({})
export default class DecoratePhone extends Vue {
    @Prop() value!: any
    @Prop() facade!: number
    /** S data **/
    current = 0
    /** E data **/
    /** S computed **/
    get imgLists() {
        return this.value
    }
    set imgLists(val) {
        this.$emit('input', val)
    }
    /** E computed **/
    changeCurrent(val: number) {
        this.current = val
        this.$emit('change', val)
    }
}
