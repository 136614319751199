


















import { Component, Prop, Vue } from 'vue-property-decorator'
import WidgetRoot from '@/components/decorate/widget-root.vue'
import Goods from '../goodsgroup/goods.vue'
@Component({
    components: {
        WidgetRoot,
        Goods,
    },
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get goods() {
        const { data, goods_type, category } = this.content
        if (goods_type == 2) {
            return [...Array(category.number).keys()].map(() => ({}))
        }
        return data.length ? data : [{}]
    }
    get btnStyle() {
        const { btn_bg_color, btn_color, btn_border_radius, btn_border_color } =
            this.styles
        const { btn_bg_type } = this.content
        const style = {
            'background-color': btn_bg_color,
            color: btn_color,
            'border-radius': `${btn_border_radius}px`,
            'border-color': btn_border_color,
        }
        if (btn_bg_type == 2) delete style['background-color']
        return style
    }
}
