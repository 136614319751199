



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Indicator from '@/components/decorate/indicator.vue'
import WidgetRoot from '@/components/decorate/widget-root.vue'
@Component({
    components: {
        Indicator,
        WidgetRoot,
    },
})
export default class Contents extends Vue {
    @Prop() content!: any
    @Prop() styles!: any

    get infoStyle() {
        const { background_image, background_type } = this.content
        if (!background_image || background_type == 1) {
            return {}
        }
        return {
            'background-image': `url(${this.$getImageUri(this.content.background_image)})`,
        }
    }

    get showAssets() {
        return (type: number) => {
            return this.content.assets.includes(type)
        }
    }
}
