var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-root',{attrs:{"styles":_vm.styles}},[_c('div',{staticClass:"rubik",style:({margin: ("-" + (_vm.styles.margin/2) + "px")})},[(_vm.content.style==1)?_c('div',{staticClass:"rubik-item1"},[(_vm.imgLists[0] && _vm.imgLists[0].url)?_c('div',{staticClass:"item-image",style:({margin: ((_vm.styles.margin/2) + "px")})},[_c('el-image',{attrs:{"fit":"cover","src":_vm.$getImageUri(_vm.imgLists[0].url)}})],1):_c('el-image',{staticStyle:{"height":"375px","width":"100%"}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1):_vm._e(),(_vm.content.style==2)?_c('div',{staticClass:"rubik-item2"},_vm._l((_vm.imgLists),function(item,index){return _c('div',{key:index,staticClass:"item-image",style:({margin: ((_vm.styles.margin/2) + "px")})},[(item && item.url)?_c('el-image',{attrs:{"fit":"cover","src":_vm.$getImageUri(item.url)}}):_c('el-image',{staticStyle:{"height":"188px","width":"100%"}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1)}),0):_vm._e(),(_vm.content.style==3)?_c('div',{staticClass:"rubik-item3"},_vm._l((_vm.imgLists),function(item,index){return _c('div',{key:index,staticClass:"item-image",style:({margin: ((_vm.styles.margin/2) + "px")})},[(item && item.url)?_c('el-image',{attrs:{"fit":"cover","src":_vm.$getImageUri(item.url)}}):_c('el-image',{staticStyle:{"height":"125px","width":"100%"}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1)}),0):_vm._e(),(_vm.content.style==4)?_c('div',{staticClass:"rubik-item4"},[_c('div',{staticClass:"item-image",style:({
                    width: '50%',
                    height: '188px',
                    top: 0,
                    left: 0,
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[0] && _vm.$getImageUri(_vm.imgLists[0].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1),_c('div',{staticClass:"item-image",style:({
                    'width': '50%',
                    'height': '94px',
                    'top':0,
                    'left': '50%'
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[1] && _vm.$getImageUri(_vm.imgLists[1].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1),_c('div',{staticClass:"item-image",style:({
                    'width': '50%',
                    'height': '94px',
                    'top': '94px',
                    'left': '50%'
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[2] && _vm.$getImageUri(_vm.imgLists[2].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1)]):_vm._e(),(_vm.content.style==5)?_c('div',{staticClass:"rubik-item5"},_vm._l((_vm.imgLists),function(item,index){return _c('div',{key:index,staticClass:"item-image"},[_c('el-image',{attrs:{"fit":"cover","src":item && _vm.$getImageUri(item.url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1)}),0):_vm._e(),(_vm.content.style==6)?_c('div',{staticClass:"rubik-item6"},[_c('div',{staticClass:"item-image",style:({
                    'width': '100%',
                    'height': '94px',
                    'top': 0,
                    'left': 0
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[0] && _vm.$getImageUri(_vm.imgLists[0].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1),_c('div',{staticClass:"item-image",style:({
                    'width': '50%',
                    'height': '94px',
                    'top':'94px',
                    'left': 0
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[1] && _vm.$getImageUri(_vm.imgLists[1].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1),_c('div',{staticClass:"item-image",style:({
                    'width': '50%',
                    'height': '94px',
                    'top': '94px',
                    'left': '50%'
                })},[_c('el-image',{attrs:{"fit":"cover","src":_vm.imgLists[2] && _vm.$getImageUri(_vm.imgLists[2].url)}},[_c('div',{staticClass:"image-error muted flex row-center",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture font-size-40"})])])],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }